.main-menu {
  position: fixed;
  bottom: 0;
  z-index: 1;
  width: 100vw;
}

.main-menu a {
  display: block;
  text-decoration: none;
  padding: 0.35rem 0.25rem 0.15rem 0.25rem;
  outline: none;
}
