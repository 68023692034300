.heading-page {
  position: relative;
}
.heading-page-prev {
  position: absolute;
  left: 0;
  cursor: pointer;
  width: 30px;
  height: 30px;
}
