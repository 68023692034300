@import url(https://fonts.googleapis.com/css?family=Roboto:300,300i,400,400i,500,500i,700&display=swap);
.heading-page {
  position: relative;
}
.heading-page-prev {
  position: absolute;
  left: 0;
  cursor: pointer;
  width: 30px;
  height: 30px;
}

.progress-bar {
  position: relative;
  height: 0.5rem;
  width: 100%;
  border-radius: 50px;
  background-color: white;
}

.filler {
  height: 100%;
  border-radius: inherit;
  transition: width 0.2s ease-in;
}

.calendar-drop-button button {
  border: 1px solid white;
  border-radius: 4px;
  padding: 4px 22px;
  font-size: 13px;
  line-height: 24px;
}

.Selectable
  .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
  background-color: #f1f1f1 !important;
  color: #b81b18;
}
.Selectable
  .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
  background-color: #b81b18 !important;
}
.Selectable .DayPicker-Day {
  border-radius: 0 !important;
}
.Selectable .DayPicker-Day--start {
  border-top-left-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
}
.Selectable .DayPicker-Day--end {
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 50% !important;
}

.layer-menu a {
  text-decoration: none;
  font-size: 1rem;
  text-transform: uppercase;
  color: #ededed;
}

.main-menu {
  position: fixed;
  bottom: 0;
  z-index: 1;
  width: 100vw;
}

.main-menu a {
  display: block;
  text-decoration: none;
  padding: 0.35rem 0.25rem 0.15rem 0.25rem;
  outline: none;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body,
html,
#root,
.grommet-wrapper {
  height: 100%;
  overflow: hidden;
}
.module-wrapper {
  height: 100%;
  overflow-y: auto;
}
.uppercase-button {
  font-size: 0.9rem;
  line-height: 0.7rem;
  text-transform: uppercase;
}
.custom-control-radius button {
  border-radius: 0.25rem;
}
.custom-control-radius button input {
  padding: 6px;
}
.filter-tabs .filter-tabs-item {
  box-shadow: none;
}
.mobile-wrapper {
  width: 100%;
  max-width: 768px !important;
  margin: 0 auto;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

