@import url('https://fonts.googleapis.com/css?family=Roboto:300,300i,400,400i,500,500i,700&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body,
html,
#root,
.grommet-wrapper {
  height: 100%;
  overflow: hidden;
}
.module-wrapper {
  height: 100%;
  overflow-y: auto;
}
.uppercase-button {
  font-size: 0.9rem;
  line-height: 0.7rem;
  text-transform: uppercase;
}
.custom-control-radius button {
  border-radius: 0.25rem;
}
.custom-control-radius button input {
  padding: 6px;
}
.filter-tabs .filter-tabs-item {
  box-shadow: none;
}
.mobile-wrapper {
  width: 100%;
  max-width: 768px !important;
  margin: 0 auto;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
