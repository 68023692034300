.progress-bar {
  position: relative;
  height: 0.5rem;
  width: 100%;
  border-radius: 50px;
  background-color: white;
}

.filler {
  height: 100%;
  border-radius: inherit;
  transition: width 0.2s ease-in;
}
